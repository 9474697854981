<template>
	<div class="client-detail">
		<div class="breadcrumb">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item to="/client">客户信息库</el-breadcrumb-item>
				<el-breadcrumb-item>客户信息详情</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="top-main copy">
			<img class="left-img" v-if="mdsClientInfo.logoUrl" @dragstart.prevent :src="mdsClientInfo.logoUrl" alt="" />
			<div class="img-left-logo" v-else style="background-color: #6daae2">
				<div class="logo-img">{{ mdsClientInfo.logoImg }}</div>
			</div>
			<div class="main-right">
				<div class="top-name">
					<div class="name">{{ mdsClientInfo.clientName }}</div>
					<div class="name-right">
						<div class="right-item" v-show="mdsClientInfo.isJoin == 0" @click="joinMyClient(1)">
							<img class="add-icon" @dragstart.prevent src="../../../assets/img/user/add.png" alt="" />
							<div>加入客户列表</div>
						</div>
						<div class="right-item" v-show="mdsClientInfo.isJoin == 1" @click="joinMyClient(0)">
							<img class="add-icon" @dragstart.prevent src="../../../assets/img/user/yichu.png" alt="" />
							<div style="color: #1181fa">移除客户列表</div>
						</div>
						<div class="right-item" v-show="mdsClientInfo.isJsubscribe == 0" @click="jsubscribeClient(1)">
							<img class="take-icon" @dragstart.prevent src="../../../assets/img/user/dingyue.png" alt="" />
							<div>加入订阅</div>
						</div>
						<div class="right-item" v-show="mdsClientInfo.isJsubscribe == 1" @click="jsubscribeClient(0)">
							<img class="take-icon" @dragstart.prevent src="../../../assets/img/user/quxiaodingyue.png" alt="" />
							<div class="take">取消订阅</div>
						</div>
						<div class="right-item" @click="toNotepad">
							<img class="notepad-icon" @dragstart.prevent src="../../../assets/img/user/notepad.png" alt="" v-show="mdsClientInfo.noteCount == 0" />
							<img src="@/assets/img/user/notepad-blue.png" @dragstart.prevent class="notepad-icon" alt="" v-show="mdsClientInfo.noteCount != 0" />
							<div
								class="notepad"
								:style="{
									color: mdsClientInfo.noteCount == 0 ? '' : '#1181FA',
								}"
							>
								<span>记事本</span>
								<span class="line">|</span>
								<span>{{ mdsClientInfo.noteCount }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="main-content">
					<div class="item-box">
						<div class="item-left">企业法人：{{ mdsClientInfo.legalPerson || "-" }}</div>
						<div class="item-right">注册资本：{{ mdsClientInfo.registeredCapital || "-" }}</div>
					</div>
					<div class="item-box">
						<div class="item-left">成立日期：{{ mdsClientInfo.establishDate || "-" }}</div>
						<div class="item-right">联系电话：{{ mdsClientInfo.phone || "-" }}</div>
					</div>
					<div class="item-box">
						<div class="item-left">
							<div>微信公众号：{{ mdsClientInfo.wechat || "-" }}</div>
						</div>
						<div class="item-right">
							企业官网：
							<el-tooltip effect="light" :content="mdsClientInfo.websiteLink" placement="bottom-start" v-if="mdsClientInfo.websiteLink">
								<span class="link text-ellipsis" @click.stop="toLink(mdsClientInfo.websiteLink)">{{ mdsClientInfo.websiteLink }}</span>
							</el-tooltip>
							<span v-else class="link-none">-</span>
						</div>
					</div>
					<div class="item-box">
						<div class="item-left" style="width: 750px">企业地址：{{ mdsClientInfo.address || "-" }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main-msg-box">
			<div class="nav-box" :class="activeName == 'navFour' ? 'nav-box-four' : ''">
				<div :class="activeName == 'navFive' ? 'nav-box-five' : ''">
					<el-tabs v-model="activeName" :before-leave="beforeleaveNav">
						<el-tab-pane label="基本信息" name="navOne" v-if="type == 1"></el-tab-pane>
						<el-tab-pane label="客户动态" name="navTwo"></el-tab-pane>
						<el-tab-pane label="招标动态" name="navThree"></el-tab-pane>
						<el-tab-pane label="中标动态" name="navSix"></el-tab-pane>
						<el-tab-pane label="历史项目数据" name="navFour"></el-tab-pane>
						<el-tab-pane label="客户资料库" name="navFive"></el-tab-pane>
					</el-tabs>
				</div>
			</div>
			<div class="nav-main">
				<div v-show="activeName == 'navOne' && type == 1" class="copy">
					<div class="empty-box" v-show="!mdsClientInfo.introduction && !clientBasic && shareholderList.length == 0 && principalList.length == 0 && investmentList.length == 0 && controlsList.length == 0 && branchesList.length == 0 && !mdsClientActualOwner" style="margin: 40px 0 30px 0">
						<empty :name="'暂无数据'"></empty>
					</div>
					<div class="main-title" v-show="mdsClientInfo.introduction">企业简介</div>
					<div class="intro" v-show="mdsClientInfo.introduction">
						{{ mdsClientInfo.introduction || "-" }}
					</div>
					<div class="main-title" v-show="clientBasic">工商信息</div>
					<div class="business-info" v-show="clientBasic">
						<div class="table-row">
							<div class="table-header">企业法人</div>
							<div class="table-mian">{{ clientBasic.legalPerson || "-" }}</div>
							<div class="table-header">经营状态</div>
							<div class="table-mian">
								{{ clientBasic.businessStatus || "-" }}
							</div>
						</div>
						<div class="table-row">
							<div class="table-header">成立时间</div>
							<div class="table-mian">
								{{ clientBasic.establishDate || "-" }}
							</div>
							<div class="table-header">核准日期</div>
							<div class="table-mian">{{ clientBasic.examineDate || "-" }}</div>
						</div>
						<div class="table-row">
							<div class="table-header">注册资本</div>
							<div class="table-mian">
								{{ clientBasic.registeredCapital || "-" }}
							</div>
							<div class="table-header">实缴资本</div>
							<div class="table-mian">{{ clientBasic.paidCapital || "-" }}</div>
						</div>
						<div class="table-row">
							<div class="table-header">统一社会信用代码</div>
							<div class="table-mian">{{ clientBasic.creditCode || "-" }}</div>
							<div class="table-header">组织机构代码</div>
							<div class="table-mian">
								{{ clientBasic.organizationCode || "-" }}
							</div>
						</div>
						<div class="table-row">
							<div class="table-header">工商注册号</div>
							<div class="table-mian">
								{{ clientBasic.registrationNumber || "-" }}
							</div>
							<div class="table-header">经营期限</div>
							<div class="table-mian">
								{{ clientBasic.businessPeriod || "-" }}
							</div>
						</div>
						<div class="table-row">
							<div class="table-header">所属行业</div>
							<div class="table-mian">
								{{ clientBasic.subordinateIndustry || "-" }}
							</div>
							<div class="table-header">企业类型</div>
							<div class="table-mian">{{ clientBasic.companyType || "-" }}</div>
						</div>
						<div class="table-row">
							<div class="table-header">登记机关</div>
							<div class="table-mian">
								{{ clientBasic.registrationAuthority || "-" }}
							</div>
							<div class="table-header">所属地区</div>
							<div class="table-mian">{{ clientBasic.area || "-" }}</div>
						</div>
						<div class="table-row">
							<div class="table-header">企业注册地址</div>
							<div class="registered-ddress">
								{{ clientBasic.address || "-" }}
							</div>
						</div>
						<div class="table-row-end">
							<div class="table-header-end">经营范围</div>
							<div class="table-mian-end">
								{{ clientBasic.businessScope || "-" }}
							</div>
						</div>
					</div>
					<div class="main-title" v-show="shareholderList.length != 0">股东信息</div>
					<div class="shareholder-table" v-show="shareholderList.length != 0">
						<el-table header-cell-class-name="table_header" :data="shareholderList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="name" label="股东名称" align="center"></el-table-column>
							<el-table-column prop="totalRealCapi" label="总实缴" align="center"></el-table-column>
							<el-table-column prop="identifyNo" label="企业证照号" align="center"></el-table-column>
							<el-table-column prop="totalShouldCapi" label="总认缴" align="center"></el-table-column>
							<el-table-column prop="stockPercent" label="持股比例" align="center"></el-table-column>
							<el-table-column prop="identifyType" label="类型" align="center"></el-table-column>
							<el-table-column prop="stockType" label="股东类型" align="center"></el-table-column>
							<el-table-column prop="capiDate" label="出资时间" align="center"></el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="shareholderTotal" @change="shareholderPaginChange" v-show="shareholderTotal != 0"></pagination>
					</div>
					<div class="main-title" v-show="principalList.length != 0">主要人员</div>
					<div class="shareholder-table" v-show="principalList.length != 0">
						<el-table header-cell-class-name="table_header" :data="principalList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="principalName" label="姓名" align="center"></el-table-column>
							<el-table-column prop="principalPosition" label="职务" align="center"></el-table-column>
							<el-table-column prop="isHistory" label="是否为历史人员" align="center">
								<template slot-scope="scope">
									<span>{{ scope.row.isHistory == "10011001" ? "是" : "否" }}</span>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="principalTotal" @change="principalPaginChange" v-show="principalTotal != 0"></pagination>
					</div>
					<div class="main-title" v-show="investmentList.length != 0">对外投资</div>
					<div class="shareholder-table" v-show="investmentList.length != 0">
						<el-table header-cell-class-name="table_header" :data="investmentList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="enterprise" label="股东名称" align="center"></el-table-column>
							<el-table-column prop="shortName" label="简称" align="center"></el-table-column>
							<el-table-column prop="amount" label="投资金额(万元)" align="center"></el-table-column>
							<el-table-column prop="percent" label="持股比例" align="center"></el-table-column>
							<el-table-column prop="newStatus" label="经营状态" align="center">
								<template slot-scope="scope">
									<span>{{ getNewStatus(scope.row.newStatus) }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="shType" label="股东类型" align="center"></el-table-column>
							<el-table-column prop="investCreditNo" label="股东统一社会信用代码" align="center"></el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="investmentTotal" @change="investmentPaginChange" v-show="investmentTotal != 0"></pagination>
					</div>
					<div class="main-title" v-show="controlsList.length != 0">实际控制企业</div>
					<div class="shareholder-table" v-show="controlsList.length != 0">
						<el-table header-cell-class-name="table_header" :data="controlsList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="pname" label="投资方" align="center"></el-table-column>
							<el-table-column prop="ename" label="投资企业名称" align="center"></el-table-column>
							<el-table-column prop="percent" label="控股比例" align="center"></el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="controlsTotal" @change="controlsPaginChange" v-show="controlsTotal != 0"></pagination>
					</div>
					<div class="main-title" v-show="branchesList.length != 0">分支机构</div>
					<div class="shareholder-table" v-show="branchesList.length != 0">
						<el-table header-cell-class-name="table_header" :data="branchesList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="name" label="分支机构名称" align="center"></el-table-column>
							<el-table-column prop="registCapi" label="注册资本" align="center"></el-table-column>
							<el-table-column prop="startDate" label="成立日期" align="center"></el-table-column>
							<el-table-column prop="belongOrg" label="登记机关" align="center"></el-table-column>
							<el-table-column prop="operName" label="企业法定代表人" align="center"></el-table-column>
							<el-table-column prop="status" label="企业状态" align="center"></el-table-column>
							<el-table-column prop="creditNo" label="统一社会信用代码" align="center"></el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="branchesTotal" @change="branchesPaginChange" v-show="branchesTotal != 0"></pagination>
					</div>

					<div class="main-title" v-show="mdsClientActualOwner != ''">疑似实控人</div>
					<div class="person-box" v-show="mdsClientActualOwner != ''">
						<RelationGraph ref="seeksRelationGraph" :options="graphOptions" />
					</div>
				</div>
				<div v-show="activeName == 'navTwo'">
					<div class="title-box">
						<div class="main-title">客户动态</div>
						<div class="total-right">
							<span>共找到</span>
							<span class="total">{{ dynamicTotal }}</span>
							<span>条结果</span>
						</div>
					</div>
					<div class="time-picker">
						<div class="custom-box">
							<div class="custom">时间</div>
							<div>
								<el-date-picker v-model="dynamicStart" type="month" :picker-options="dynamicStartTime" placeholder="开始时间" value-format="yyyy-MM"></el-date-picker>
							</div>
							<div class="to">至</div>
							<div>
								<el-date-picker v-model="dynamicEnd" type="month" :picker-options="dynamicEndTime" placeholder="结束时间" value-format="yyyy-MM"></el-date-picker>
							</div>
						</div>
						<el-button type="primary" @click="dynamicQuery">查询</el-button>
					</div>
					<div class="empty-box" v-show="dynamicTotal == 0">
						<empty :name="'暂无数据'"></empty>
					</div>
					<div class="list-item" v-for="item in pageDynamic" :key="item.dynamicId" @click="toDynamicDetail(item.dynamicId)">
						<div class="name-left">
							<div class="tag-icon"></div>
							<el-tooltip effect="light" :content="item.title" placement="bottom-start">
								<div class="name-box text-ellipsis">
									{{ item.title }}
								</div>
							</el-tooltip>
						</div>
						<div class="date-right">
							<div class="serial-code">{{ item.websiteLocation }}</div>
							<div class="date">{{ item.publishedDate }}</div>
						</div>
					</div>
					<div class="pagination-box">
						<pagination ref="pagination" :total="dynamicTotal" @change="dynamicPaginChange" v-show="dynamicTotal != 0"></pagination>
					</div>
				</div>
				<div v-show="activeName == 'navThree'">
					<div class="title-box">
						<div class="main-title">招标动态</div>
						<div class="total-right">
							<span>共找到</span>
							<span class="total">{{ biddingTotal }}</span>
							<span>条结果</span>
						</div>
					</div>
					<div class="time-picker">
						<div class="custom-box">
							<div class="custom">时间</div>
							<div>
								<el-date-picker v-model="bidsStart" type="month" :picker-options="bidsStartTime" placeholder="开始时间" value-format="yyyy-MM"></el-date-picker>
							</div>
							<div class="to">至</div>
							<div>
								<el-date-picker v-model="bidsEnd" type="month" :picker-options="bidsEndTime" placeholder="结束时间" value-format="yyyy-MM"></el-date-picker>
							</div>
						</div>
						<div class="search-right">
							<el-input placeholder="请输入招标公告标题" prefix-icon="el-icon-search" v-model="bidInput" maxlength="200" clearable></el-input>
							<el-button type="primary" @click="bidsQuery">查询</el-button>
						</div>
					</div>
					<div class="empty-box" v-show="biddingTotal == 0">
						<empty :name="'暂无数据'"></empty>
					</div>
					<div class="list-bidding" v-for="(item, index) in pageBidding" :key="index" @click="toBidsDetail(item)">
						<div class="name-top">
							<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
								<div class="text-ellipsis">
									{{ item.projectName }}
								</div>
							</el-tooltip>
						</div>
						<div class="bottom-box">
							<div class="tag-bottom">
								<div class="tag-item">招标单位：{{ item.tenderee || "-" }}</div>
								<div class="tag-item">地址：{{ item.city }}</div>
							</div>
							<div class="date">发布时间：{{ item.publishedDate }}</div>
						</div>
					</div>
					<div class="pagination-box">
						<pagination ref="pagination" :total="biddingTotal" @change="biddingPaginChange" v-show="biddingTotal != 0"></pagination>
					</div>
				</div>
				<div v-show="activeName == 'navSix'">
					<div class="title-box">
						<div class="main-title">中标动态</div>
						<div class="total-right">
							<span>共找到</span>
							<span class="total">{{ biddingTotal }}</span>
							<span>条结果</span>
						</div>
					</div>
					<div class="time-picker">
						<div class="custom-box">
							<div class="custom">时间</div>
							<div>
								<el-date-picker v-model="bidsStart" type="month" :picker-options="bidsStartTime" placeholder="开始时间" value-format="yyyy-MM"></el-date-picker>
							</div>
							<div class="to">至</div>
							<div>
								<el-date-picker v-model="bidsEnd" type="month" :picker-options="bidsEndTime" placeholder="结束时间" value-format="yyyy-MM"></el-date-picker>
							</div>
						</div>
						<div class="search-right">
							<el-input placeholder="请输入招标公告标题" prefix-icon="el-icon-search" v-model="bidInput" maxlength="200" clearable></el-input>
							<el-button type="primary" @click="bidsQuery">查询</el-button>
						</div>
					</div>
					<div class="empty-box" v-show="biddingTotal == 0">
						<empty :name="'暂无数据'"></empty>
					</div>
					<div class="list-bidding" v-for="(item, index) in pageBiddingWinner" :key="index" @click="toSuccessfulBidsDetail(item)">
						<div class="name-top">
							<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
								<div class="text-ellipsis">
									{{ item.projectName }}
								</div>
							</el-tooltip>
						</div>
						<div class="bottom-box">
							<div class="tag-bottom">
								<div class="tag-item">中标单位：{{ item.bidWinner || "-" }}</div>
								<div class="tag-item">地址：{{ item.city }}</div>
							</div>
							<div class="date">发布时间：{{ item.publishedDate }}</div>
						</div>
					</div>
					<div class="pagination-box">
						<pagination ref="pagination" :total="biddingTotal" @change="biddingPaginChange" v-show="biddingTotal != 0"></pagination>
					</div>
				</div>
				<div v-show="activeName == 'navFour'">
					<div class="history-title">
						<div style="display: flex; align-items: center">
							<div class="main-title">历史项目数据</div>
							<!-- <div v-show="isTime" style="height: 12px; margin-left: 20px">时间：2021年1月-2022年12月</div> -->
						</div>
						<div class="title-right">
							<div class="table-show" v-show="showType == 1" @click="clickShowType(2)">
								<div class="icon-img"></div>
								<span>表格展示</span>
							</div>
							<div class="atlas-show" v-show="showType == 2" @click="clickShowType(1)">
								<span class="iconfont icon-tupu-"></span>
								<span>图谱展示</span>
							</div>
							<!-- <div v-if="!isSensitive" class="download-box" @click="toDownload">
								<span class="iconfont icon-download"></span>
								<span>下载</span>
							</div> -->
							<!-- <div v-if="isSensitive" class="download-box">
								<span>开通权限可查看全部信息</span>
								<el-button style="margin-left: 10px;" type="primary" size="mini" @click="toPay">立即开通</el-button>
							</div> -->
						</div>
					</div>
					<div class="nav-list">
						<div class="nav-item" :class="conPO.navIndex == 1 ? 'nav-item-select' : ''" @click="clickNav(1)">
							按招标人
						</div>
						<div class="nav-item" :class="conPO.navIndex == 2 ? 'nav-item-select' : ''" @click="clickNav(2)">
							按中标人
						</div>
					</div>
					<div class="form-top">
						<div class="form-lable" style="width: 50px">时间</div>
						<div class="date-picker">
							<el-date-picker v-model="conPO.dateStart" type="month" :picker-options="startTime" placeholder="选择开始日期" value-format="yyyy-MM"></el-date-picker>
						</div>
						<div class="line"></div>
						<div class="date-picker">
							<el-date-picker v-model="conPO.dateEnd" type="month" :picker-options="endTime" placeholder="选择结束日期" value-format="yyyy-MM"></el-date-picker>
						</div>
						<div class="form-lable lable">项目金额</div>
						<el-form ref="rulesPrice" :model="conPO" :rules="rules" style="display: flex; align-items: center">
							<div>
								<el-form-item prop="winnerPriceStart" style="margin-bottom: 0">
									<el-input v-model="conPO.winnerPriceStart" oninput="value=value.replace(/[^0-9.]/g,'')" @blur="conPO.winnerPriceStart = $event.target.value" placeholder="最低金额（万）" @change="blurPrice('start')" clearable></el-input>
								</el-form-item>
							</div>
							<div class="line" style="margin-top: 1px"></div>
							<div>
								<el-form-item prop="winnerPriceEnd" style="margin-bottom: 0">
									<el-input v-model="conPO.winnerPriceEnd" oninput="value=value.replace(/[^0-9.]/g,'')" @blur="conPO.winnerPriceEnd = $event.target.value" placeholder="最高金额（万）" @change="blurPrice('end')" clearable></el-input>
								</el-form-item>
							</div>
						</el-form>
						<div class="note">
							<span class="iconfont icon-zhushibiaozhui"></span>
							<div class="note-title animated fadeIn">
								<span>某金额以上可只填最低金额；某金额以下可只填最高金额</span>
							</div>
						</div>
						<div class="search-button">
							<el-button type="primary" @click="handleQuery">搜索</el-button>
						</div>
					</div>
					<by-tenderee v-show="conPO.navIndex == 1" ref="byTenderee" @isSensitive="getIsSensitive"></by-tenderee>
					<by-bidwinner v-show="conPO.navIndex == 2" ref="byBidwinner" @isSensitive="getIsSensitive"></by-bidwinner>
				</div>
				<div v-show="activeName == 'navFive'">
					<div class="main-title">客户资料库</div>
					<div class="datum-box">
						<div class="datum-left">
							<div class="datum-title">资料分类</div>
							<div class="datum-search">
								<el-input placeholder="请输入分类名称" prefix-icon="el-icon-search" v-model="typeName" @blur="typeSearch" @keyup.enter.native="typeSearch" maxlength="10"></el-input>
							</div>
							<div class="file-list">
								<div class="empty-box" v-show="fileList.length == 0" style="margin-top: 40px">
									<empty :name="'暂无数据'"></empty>
								</div>
								<div class="file-item" :class="fileIndex == i ? 'file-item-select' : ''" v-for="(item, i) in fileList" :key="item.folderId" @click="selectFile(i, item.folderId)">
									<img class="file-icon" @dragstart.prevent v-show="item.isShare == 0" src="../../../assets/img/user/wenjian.png" alt="" />
									<img class="file-icon" @dragstart.prevent v-show="item.isShare == 1" src="../../../assets/img/user/share.png" alt="" />
									<div class="file-name">{{ item.folderName }}</div>
								</div>
							</div>
						</div>
						<div class="datum-right">
							<div class="empty-box" v-show="fileTotal == 0" style="margin-top: 200px">
								<empty :name="'暂无数据'"></empty>
							</div>
							<div class="datum-table" v-show="fileTotal != 0">
								<el-table :data="fileTable" header-cell-class-name="table_header" style="width: 100%" height="520">
									<el-table-column prop="fileName" label="文件名称" align="center">
										<template slot-scope="scope">
											<span style="cursor: pointer; color: #1181fa" @click="handleViewFile(scope.row)">{{ scope.row.fileName }}</span>
										</template>
									</el-table-column>
									<el-table-column prop="fileSizes" label="文件大小" align="center">
										<template slot-scope="scope">
											<span>{{ scope.row.fileSizes }}M</span>
										</template>
									</el-table-column>
									<el-table-column prop="createDate" label="上传时间" align="center"></el-table-column>
								</el-table>
							</div>
							<div class="pagination-datum">
								<pagination ref="pagination" :total="fileTotal" @change="filePaginChange" v-show="fileTotal != 0"></pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<notepad-modal ref="notepadModal"></notepad-modal>
		<!-- 富文本预览  dialog -->
		<Modal :visible.sync="dtlVisible" class="dialog-view-style">
			<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">文档预览</div>
			<div class="report-preview ql-editor" id="dtlPreview"></div>
		</Modal>
		<ToPayOrMember ref="toPayOrMember" :showCommonText="false" :showMemberBtn="showMemberBtn" :text="text"></ToPayOrMember>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import LoginModal from "@/components/loginModal";
import MemberModal from "@/components/MemberModal";
import ByTenderee from "./byTenderee";
import ByBidwinner from "./byBidwinner";
import html2canvas from "html2canvas";
import NotepadModal from "@/components/notepadModal";
import Empty from "@/components/Empty";
import RelationGraph from "relation-graph";
import Modal from "@/components/Modal";
import ToPayOrMember from "@/components/ToPayOrMember";
export default {
	components: {
		Pagination,
		LoginModal,
		Modal,
		MemberModal,
		ByTenderee,
		ByBidwinner,
		NotepadModal,
		Empty,
		RelationGraph,
		ToPayOrMember,
	},
	data() {
		let price = (rule, value, callback) => {
			if (!value) {
				callback();
			} else {
				const reg = /^[0-9]{1,18}([.][0-9]{1,6})?$/;
				if (reg.test(value)) {
					callback();
				} else {
					return callback(new Error("最多18位整数，6位小数"));
				}
			}
		};
		return {
			showMemberBtn: true,
			graphOptions: {
				// allowShowMiniToolBar: false,
				allowShowMiniNameFilter: false,
				allowSwitchLineShape: false,
				allowSwitchJunctionPoint: false,
				defaultFocusRootNode: false,
				disableZoom: true,
				layouts: [
					{
						label: "自动布局",
						layoutName: "force",
						layoutClassName: "seeks-layout-force",
					},
				],
			},
			type: "", // 1 企业用户  2 非企业用户
			id: "", // 客户id
			activeName: "navOne", // tab栏状态
			mdsClientInfo: {}, // 基本信息
			clientBasic: "", // 工商信息
			mdsClientActualOwner: "",
			shareholderPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			shareholderTotal: 0,
			shareholderList: [], // 股东信息
			principalPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			principalTotal: 0,
			principalList: [], // 主要人员
			investmentPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			investmentTotal: 0,
			investmentList: [], // 对外投资
			controlsPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			controlsTotal: 0,
			controlsList: [], // 实际控制企业
			branchesPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			branchesTotal: 0,
			branchesList: [], // 分支机构
			showType: 1, // 1 展示图谱 2 展示表格
			conPO: {
				navIndex: 1, //1 招标人 2中标人
				tenderee: "",
				bidWinner: "",
				winnerPriceStart: "",
				winnerPriceEnd: "",
				dateStart: "",
				dateEnd: "",
				pageSize: 10, //页数
				pageNum: 1, // 页码
			}, // 查询条件
			params: {},
			rules: {
				winnerPriceStart: [
					{
						validator: price,
						trigger: "blur",
					},
				],
				winnerPriceEnd: [
					{
						validator: price,
						trigger: "blur",
					},
				],
			},
			startTime: {
				disabledDate: time => {
					if (this.conPO.dateEnd) {
						return time.getTime() > new Date(this.conPO.dateEnd).getTime();
					}
				},
			}, // 控制开始时间
			endTime: {
				disabledDate: time => {
					if (this.conPO.dateStart) {
						return time.getTime() < new Date(this.conPO.dateStart).getTime();
					}
				},
			}, // 控制结束时间
			dynamicTotal: 0,
			dynamicStart: "",
			dynamicEnd: "",
			dynamicStartTime: {
				disabledDate: time => {
					if (this.dynamicEnd) {
						return time.getTime() > new Date(this.dynamicEnd).getTime();
					}
				},
			},
			dynamicEndTime: {
				disabledDate: time => {
					if (this.dynamicStart) {
						return time.getTime() < new Date(this.dynamicStart).getTime();
					}
				},
			},
			dynamicPO: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			pageDynamic: [],
			bidsStart: "",
			bidsEnd: "",
			bidsStartTime: {
				disabledDate: time => {
					if (this.bidsEnd) {
						return time.getTime() > new Date(this.bidsEnd).getTime();
					}
				},
			},
			bidsEndTime: {
				disabledDate: time => {
					if (this.bidsStart) {
						return time.getTime() < new Date(this.bidsStart).getTime();
					}
				},
			},
			bidInput: "",
			biddingTotal: 0,
			biddingWinnerTotal: 0,
			bidsPO: {
				platform: 1,
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},

			pageBidding: [], //招标动态
			pageBiddingWinner: [], //中标动态
			typeName: "",
			fileIndex: 0,
			fileList: [],
			fileTable: [],
			filePO: {
				folderId: "",
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			fileTotal: 0,
			dtlVisible: false,
			isTime: false,
			isSensitive: false,
			orderPO: {},
			text: "",
		};
	},
	mounted() {
		let id = this.$route.query.id;
		this.id = id;
		let type = this.$route.query.type;
		this.type = type;
		this.getClientInfoDetail();
		if (type == 1) {
			// 企业客户
			this.getClientInfoDetailShareholder();
		} else {
			// 非企业客户
			this.activeName = "navTwo";
			this.getClientInfoDynamic();
		}
		//获取时间限制
		this.getTimeSelectorHistory();
		this.$nextTick(() => {
			if (this.$route.query.dynamic == 1) {
				this.activeName = "navTwo";
			} else if (this.$route.query.dynamic == 2) {
				this.activeName = "navThree";
			}
		});
		// 数据收费订单预览
		if (this.$route.query.orderId) {
			this.activeName = "navFour";
			// 获取数据收费订单查询参数
			this.$api.personalCenter
				.getDataPreview({
					orderId: this.$route.query.orderId,
				})
				.then(res => {
					let dataDesc = JSON.parse(res.data.dataDesc);
					dataDesc.params.dateStart = dataDesc.params.dateStart.slice(0, 7);
					dataDesc.params.dateEnd = dataDesc.params.dateEnd.slice(0, 7);
					this.orderPO = dataDesc.params;
					if (dataDesc.dataType == 1) {
						// 按招标人
						this.activeName = "navFour";
						this.conPO.navIndex = 1;
						this.$refs.byTenderee.getParams(dataDesc.params, 1);
					} else {
						// 按中标人
						this.activeName = "navFour";
						this.conPO.navIndex = 2;
						this.$refs.byBidwinner.getParams(dataDesc.params, 1);
					}
				});
		}
	},
	methods: {
		getTimeSelectorHistory() {
			return new Promise((resolve, reject) => {
				this.$api.industry
					.getTimeSelectorHistory()
					.then(res => {
						// this.timeLimit = res.data
						this.conPO.dateEnd = res.data.maxDateTime;
						let year = this.conPO.dateEnd.split("-")[0];
						let month = this.conPO.dateEnd.split("-")[1];
						console.log(year, month);
						this.conPO.dateEnd = `${year}-${month}`;
						if (month < 3) {
							year = year - 1;
							month = Number(month) + 10;
						} else {
							month = Number(month) - 2;
						}
						month = month < 10 ? "0" + month : month;
						this.conPO.dateStart = `${year}-${month}`;
						this.conPO.defaultAccess = true;
						resolve();
						// console.log('this.conPO',this.conPO)
						// this.$refs.byTenderee.init(this.conPO);
					})
					.finally(() => {
						resolve();
					});
			});
		},
		toPay() {
			this.showPayBtn = true;
			const params = this.conPO;
			this.$api.successful
				.getPayOrderDeatil(this.conPO.navIndex, {...params, isHistoryPro: false})
				.then(res => {
					this.orderData = res.data;
					this.$refs.toPayOrMember.orderData = res.data;
					this.$refs.toPayOrMember.openDialog();
				})
				.catch(error => {
					console.log("error", error);
					this.$message.error(error?.msg);
				});
		},
		getIsSensitive(isSensitive) {
			this.isSensitive = isSensitive;
		},
		async clickNav(type) {
			this.reset();
			await this.getTimeSelectorHistory();
			let {conPO, mdsClientInfo, id} = this;
			conPO.clientId = id;
			conPO.navIndex = type;
			conPO.defaultAccess = true;
			if (type == 1) {
				conPO.tenderee = mdsClientInfo.clientName;
				this.$refs.byTenderee.getParams(conPO, 1);
			} else {
				conPO.bidWinner = mdsClientInfo.clientName;
				this.$refs.byBidwinner.getParams(conPO, 1);
			}
			this.conPO = conPO;
		},
		coloring() {
			let colors = ["#90cfb7", "#8ca3cc", "#e8ab6f", "#6daae2", "#c8b7d8"];
			let index = Math.floor(Math.random() * 5);
			return colors[index];
		},
		onLineClick() {
			return;
		},
		reset() {
			this.conPO.navIndex = 1;
			this.conPO.tenderee = "";
			this.conPO.bidWinner = "";
			this.conPO.winnerPriceStart = "";
			this.conPO.winnerPriceEnd = "";
			this.conPO.dateStart = "";
			this.conPO.dateEnd = "";
			this.conPO.pageSize = 10; //页数
			this.conPO.pageNum = 1;
			this.showType = 1;
		},
		// 判断经营状态
		getNewStatus(type) {
			if (type == 1) {
				return "存续";
			} else if (type == 2) {
				return "注销";
			} else if (type == 3) {
				return "吊销";
			} else if (type == 4) {
				return "撤销";
			} else if (type == 5) {
				return "迁出";
			} else if (type == 6) {
				return "设立中";
			} else if (type == 7) {
				return "清算中";
			} else if (type == 8) {
				return "停业";
			} else if (type == 9) {
				return "其他";
			}
		},
		toMember() {
			let url = this.$router.resolve({
				path: `/member`,
			});
			window.open(url.href, "_blank");
		},
		// 切换tab栏前
		beforeleaveNav(activeName) {
			if (activeName == "navTwo") {
				this.getClientInfoDynamic();
			} else if (activeName == "navThree") {
				this.bidsEnd = "";
				this.bidsStart = "";
				this.bidInput = "";
				this.getClientInfoTenderInfo();
				// this.$api.industry
				// 	.getClientInfoDetail({
				// 		clientId: this.id,
				// 	})
				// 	.then(res => {
				// 		this.mdsClientInfo.clientName = res.data.mdsClientInfo.clientName;
				// 		this.getClientInfoTenderInfo();
				// 	})
				// 	.catch(msg => {
				// 		if (msg?.msg) {
				// 			this.$message.error(msg?.msg);
				// 		}
				// 	});
			} else if (activeName == "navFour") {
				let {conPO, mdsClientInfo, id} = this;
				conPO.clientId = id;
				conPO.tenderee = mdsClientInfo.clientName;
				if (!this.$route.query.orderId) {
					this.$refs.byTenderee.getParams(conPO, 1);
				}
			} else if (activeName == "navFive") {
				if (!this.filePO.folderId) {
					this.getClientInfoArchivesFolder("初始化");
				}
			} else if (activeName == "navSix") {
				this.bidsEnd = "";
				this.bidsStart = "";
				this.bidInput = "";
				this.getFindPageForClient();
			}
		},
		// 获取基本信息
		getClientInfoDetail() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.industry
				.getClientInfoDetail({
					clientId: this.id,
				})
				.then(res => {
					let data = res.data;
					if (!data.mdsClientInfo.logoUrl) {
						this.$api.industry
							.getCompanyName({
								companyName: data.mdsClientInfo.clientName,
							})
							.then(ress => {
								data.mdsClientInfo.logoImg = ress.msg;
								// data.mdsClientInfo.bcColor = this.coloring();
								this.$forceUpdate();
							})
							.catch(msg => {
								if (msg?.msg) {
									this.$message.error(msg?.msg);
								}
							});
					}
					this.mdsClientInfo = data.mdsClientInfo;
					if (data.clientBasic) {
						this.clientBasic = data.clientBasic;
					}
					if (data.mdsClientActualOwner) {
						let owner = data.mdsClientActualOwner;
						this.mdsClientActualOwner = owner;
						let nodes = JSON.parse(owner.nodes);
						let links = JSON.parse(owner.links);
						nodes.forEach(item => {
							item.text = item.name;
							item.color = "#1181FA";
							item.fontColor = "#fff";
							item.nodeShape = 1;
							item.borderColor = "transparent";
							delete item.flated;
							delete item.isKey;
							delete item.name;
							delete item.type;
							delete item.uid;
						});
						links.forEach(item => {
							item.text = item.properties[0].relationPercent;
							item.from = item.sourceId + "";
							item.to = item.targetId + "";
							item.lineWidth = 2;
							delete item.properties;
							delete item.sourceId;
							delete item.targetId;
							delete item.type;
						});

						let __graph_json_data = {
							rootId: owner.actualOwnerId,
							nodes,
							links,
						};
						this.$refs.seeksRelationGraph.setJsonData(__graph_json_data);
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 获取股东信息
		getClientInfoDetailShareholder() {
			// const loading = this.$loading({
			//   lock: true,
			//   text: "加载中...",
			//   spinner: "el-icon-loading",
			//   background: "rgba(255, 255, 255, 0.3)",
			//   fullscreen: false,
			// });

			let {shareholderPo, id} = this;
			shareholderPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailShareholder(shareholderPo)
				.then(res => {
					let shareholderList = res.rows;
					shareholderList.forEach((item, index) => {
						item.index = (shareholderPo.pageNum - 1) * shareholderPo.pageSize + index + 1;
					});
					this.shareholderList = shareholderList;
					this.shareholderTotal = res.total;
					this.getClientInfoDetailPrincipalStaff();
					this.getClientInfoDetailInvestment();
					this.getClientInfoDetailActualControls();
					this.getClientInfoDetailBranches();
				})
				.catch(msg => {
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
			// .finally(() => {
			//   loading.close();
			// });
		},
		// 股东信息分页
		shareholderPaginChange(page, pageSize) {
			this.shareholderPo.pageNum = page;
			this.shareholderPo.pageSize = pageSize;
			this.getClientInfoDetailShareholder();
		},
		// 获取主要人员
		getClientInfoDetailPrincipalStaff() {
			// const loading = this.$loading({
			//   lock: true,
			//   text: "加载中...",
			//   spinner: "el-icon-loading",
			//   background: "rgba(255, 255, 255, 0.3)",
			//   fullscreen: false,
			// });

			let {principalPo, id} = this;
			principalPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailPrincipalStaff(principalPo)
				.then(res => {
					let principalList = res.rows;
					principalList.forEach((item, index) => {
						item.index = (principalPo.pageNum - 1) * principalPo.pageSize + index + 1;
					});
					this.principalList = principalList;
					this.principalTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
			// .finally(() => {
			//   loading.close();
			// });
		},
		// 主要人员分页
		principalPaginChange(page, pageSize) {
			this.principalPo.pageNum = page;
			this.principalPo.pageSize = pageSize;
			this.getClientInfoDetailPrincipalStaff();
		},
		// 获取企业对外投资
		getClientInfoDetailInvestment() {
			// const loading = this.$loading({
			//   lock: true,
			//   text: "加载中...",
			//   spinner: "el-icon-loading",
			//   background: "rgba(255, 255, 255, 0.3)",
			//   fullscreen: false,
			// });

			let {investmentPo, id} = this;
			investmentPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailInvestment(investmentPo)
				.then(res => {
					let investmentList = res.rows;
					investmentList.forEach((item, index) => {
						item.index = (investmentPo.pageNum - 1) * investmentPo.pageSize + index + 1;
					});
					this.investmentList = investmentList;
					this.investmentTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
			// .finally(() => {
			//   loading.close();
			// });
		},
		// 企业对外投资分页
		investmentPaginChange(page, pageSize) {
			this.investmentPo.pageNum = page;
			this.investmentPo.pageSize = pageSize;
			this.getClientInfoDetailInvestment();
		},
		// 获取实际控制企业
		getClientInfoDetailActualControls() {
			// const loading = this.$loading({
			//   lock: true,
			//   text: "加载中...",
			//   spinner: "el-icon-loading",
			//   background: "rgba(255, 255, 255, 0.3)",
			//   fullscreen: false,
			// });

			let {controlsPo, id} = this;
			controlsPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailActualControls(controlsPo)
				.then(res => {
					let controlsList = res.rows;
					controlsList.forEach((item, index) => {
						item.index = (controlsPo.pageNum - 1) * controlsPo.pageSize + index + 1;
					});
					this.controlsList = controlsList;
					this.controlsTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
			// .finally(() => {
			//   loading.close();
			// });
		},
		// 实际控制企业分页
		controlsPaginChange(page, pageSize) {
			this.controlsPo.pageNum = page;
			this.controlsPo.pageSize = pageSize;
			this.getClientInfoDetailActualControls();
		},
		// 获取分支机构
		getClientInfoDetailBranches() {
			// const loading = this.$loading({
			//   lock: true,
			//   text: "加载中...",
			//   spinner: "el-icon-loading",
			//   background: "rgba(255, 255, 255, 0.3)",
			//   fullscreen: false,
			// });

			let {branchesPo, id} = this;
			branchesPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailBranches(branchesPo)
				.then(res => {
					let branchesList = res.rows;
					branchesList.forEach((item, index) => {
						item.index = (branchesPo.pageNum - 1) * branchesPo.pageSize + index + 1;
					});
					this.branchesList = branchesList;
					this.branchesTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
			// .finally(() => {
			//   loading.close();
			// });
		},
		// 分支机构分页
		branchesPaginChange(page, pageSize) {
			this.branchesPo.pageNum = page;
			this.branchesPo.pageSize = pageSize;
			this.getClientInfoDetailBranches();
		},
		// 加入-移除客户列表
		joinMyClient(type) {
			this.$api.industry
				.joinMyClient({
					clientId: this.id,
				})
				.then(() => {
					if (type == 1) {
						this.$message.success("已加入客户列表");
						this.mdsClientInfo.isJoin = 1;
						this.$router.replace(`/client/consumer-detail?id=${this.id}&type=${this.type}&pathType=2`);
					}
				})
				.catch(msg => {
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 订阅-取消订阅
		jsubscribeClient(type) {
			this.$api.industry
				.jsubscribeClient({
					clientId: this.id,
				})
				.then(() => {
					if (type == 1) {
						this.$message.success("订阅成功");
						this.mdsClientInfo.isJsubscribe = 1;
					} else {
						this.$message.success("已取消订阅");
						this.mdsClientInfo.isJsubscribe = 0;
					}
				})
				.catch(msg => {
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 客户动态搜索
		dynamicQuery() {
			this.dynamicPO.pageNum = 1;
			this.dynamicPO.pageSize = 10;
			this.getClientInfoDynamic();
		},
		// 获取客户动态
		getClientInfoDynamic() {
			let {dynamicPO, dynamicStart, dynamicEnd, id} = this;
			dynamicPO.clientId = id;
			dynamicPO.dateStart = dynamicStart;
			dynamicPO.dateEnd = dynamicEnd;
			this.$api.industry
				.getClientInfoDynamic(dynamicPO)
				.then(res => {
					this.pageDynamic = res.rows;
					this.dynamicTotal = res.total;
				})
				.catch(msg => {
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 客户动态分页
		dynamicPaginChange(page, pageSize) {
			this.dynamicPO.pageNum = page;
			this.dynamicPO.pageSize = pageSize;
			this.getClientInfoDynamic();
		},
		// 点击进入客户动态详情
		toDynamicDetail(id) {
			let url = this.$router.resolve({
				path: `/industry/dynamic-detail?id=${id}&clientId=${this.id}`,
			});
			window.open(url.href, "_blank");
		},
		// 招标动态搜索
		bidsQuery() {
			this.bidsPO.pageNum = 1;
			this.bidsPO.pageSize = 10;
			if (this.activeName == "navThree") {
				this.getClientInfoTenderInfo();
			} else if (this.activeName == "navSix") {
				this.getFindPageForClient();
			}
		},
		// 获取招标动态
		getClientInfoTenderInfo() {
			let {bidsPO, bidsStart, bidsEnd, mdsClientInfo, bidInput} = this;
			bidsPO.tenderee = mdsClientInfo.clientName;
			bidsPO.dateStart = bidsStart;
			bidsPO.dateEnd = bidsEnd;
			bidsPO.search = bidInput;
			this.$api.industry
				.getClientInfoTenderInfo(bidsPO)
				.then(res => {
					this.pageBidding = res.rows;
					this.biddingTotal = res.total;
				})
				.catch(msg => {
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 获取中标动态
		getFindPageForClient() {
			let {bidsPO, bidsStart, bidsEnd, mdsClientInfo, bidInput} = this;
			bidsPO.bidWinner = mdsClientInfo.clientName;
			bidsPO.dateStart = bidsStart;
			bidsPO.dateEnd = bidsEnd;
			bidsPO.search = bidInput;
			this.$api.industry
				.getFindPageForClient(bidsPO)
				.then(res => {
					this.pageBiddingWinner = res.rows;
					this.biddingTotal = res.total;
				})
				.catch(msg => {
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 招标动态分页
		biddingPaginChange(page, pageSize) {
			this.bidsPO.pageNum = page;
			this.bidsPO.pageSize = pageSize;
			if (this.activeName == "navThree") {
				this.getClientInfoTenderInfo();
			} else if (this.activeName == "navSix") {
				this.getFindPageForClient();
			}
		},
		// 点击进入招标动态详情
		toBidsDetail(item) {
			let url = this.$router.resolve({
				path: `/industry/bidding-detail?id=${item.tenderId}`,
			});
			window.open(url.href, "_blank");
		},
		// 点击进入中标动态详情
		toSuccessfulBidsDetail(item) {
			let url = this.$router.resolve({
				path: `/project/bidding-project-detail?id=${item.id}`,
			});
			window.open(url.href, "_blank");
		},
		// 资料分类搜索
		typeSearch() {
			this.fileList = [];
			this.fileIndex = 0;
			this.getClientInfoArchivesFolder("搜索");
		},
		// 获取资料分类文件夹
		getClientInfoArchivesFolder(init) {
			let {id, typeName} = this;
			this.$api.industry
				.getClientInfoArchivesFolder({
					clientId: id,
					folderName: typeName,
				})
				.then(res => {
					this.fileList = res.data;
					if (init && res.data.length != 0) {
						this.filePO.folderId = res.data[0].folderId;
						this.getClientInfoArchivesFile();
					}
				})
				.catch(msg => {
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 获取资料分类文件夹列表
		getClientInfoArchivesFile() {
			let {id, filePO} = this;
			filePO.clientId = id;
			this.$api.industry
				.getClientInfoArchivesFile(filePO)
				.then(res => {
					this.fileTable = res.data.data;
					this.fileTotal = res.data.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},

		// 选择资料分类
		selectFile(i, id) {
			this.fileIndex = i;
			this.filePO.folderId = id;
			this.filePO.pageNum = 1;
			this.filePO.pageSize = 10;
			this.getClientInfoArchivesFile();
		},
		// 文件列表分页
		filePaginChange(page, pageSize) {
			this.filePO.pageNum = page;
			this.filePO.pageSize = pageSize;
			this.getClientInfoArchivesFile();
		},
		// 点击记事本
		toNotepad() {
			this.$api.industry
				.getClientInfoNotePad({
					clientId: this.id,
				})
				.then(res => {
					this.$refs.notepadModal.list = res.data;
					this.$refs.notepadModal.content = "";
					this.$refs.notepadModal.activeName = "navOne";
					this.$refs.notepadModal.id = this.id;
					this.$refs.notepadModal.visible = true;
					this.$refs.notepadModal.editType = false;
				})
				.catch(msg => {
					if (msg.code == "10001111") {
						this.$refs.toPayOrMember.openDialog();
						this.showPayBtn = false;
					} else {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 点击跳转公司官网
		toLink(url) {
			this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				window.open(url, "_blank");
			});
		},
		toIndividual(scope) {
			console.log(scope);
		},
		// 点击表格展示或图谱展示
		clickShowType(type) {
			if (!this.params) {
				// 没点搜索时
				return;
			}
			let {conPO, mdsClientInfo, id} = this;
			conPO.clientId = id;
			conPO.moduleCode = 1007;
			conPO.childModuleCode = 10071001;
			conPO.isHistoryPro = true;
			this.showType = type;
			if (conPO.navIndex == 1) {
				conPO.tenderee = mdsClientInfo.clientName;
				if (Object.keys(this.orderPO).length > 0) {
					conPO = this.orderPO;
				}
				if (type == 1) {
					this.$refs.byTenderee.getParams(conPO, 1);
				} else {
					this.$refs.byTenderee.getParams(conPO, 2);
				}
			} else {
				conPO.bidWinner = mdsClientInfo.clientName;
				if (Object.keys(this.orderPO).length > 0) {
					conPO = this.orderPO;
				}
				if (type == 1) {
					this.$refs.byBidwinner.getParams(conPO, 1);
				} else {
					this.$refs.byBidwinner.getParams(conPO, 2);
				}
			}
		},
		// 下载
		toDownload() {
			// if (!this.params) {
			//   // 没点搜索时
			//   return;
			// }
			// this.$message.error("暂无下载权限");

			let {conPO, showType, orderPO} = this;
			let paramsObj = JSON.parse(JSON.stringify(conPO));
			delete paramsObj.pageNum;
			delete paramsObj.pageSize;
			if (Object.keys(orderPO).length > 0) {
				paramsObj = JSON.parse(JSON.stringify(orderPO));
			}
			if (showType == 1) {
				// 图谱下载
				if (conPO.navIndex == 1) {
					if (this.$refs.byTenderee.$refs.mind.isData) {
						this.exportImg(document.getElementById("historyTenderee"), "历史项目数据-按招标人", "png");
					}
				} else {
					if (this.$refs.byBidwinner.$refs.mind.isData) {
						this.exportImg(document.getElementById("historyBidwinner"), "历史项目数据-按中标人", "png");
					}
				}
			} else {
				if (this.$refs.byTenderee.isData) {
					// 表格下载
					const loading = this.$loading({
						lock: true,
						text: "导出中...",
						spinner: "el-icon-loading",
						background: "rgba(255, 255, 255, 0.3)",
						fullscreen: false,
					});
					this.$api.industry
						.exportHistoryPro(paramsObj)
						.then(res => {
							this.$utils.downloadFile(res, "历史项目数据-按招标人.xlsx");
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						})
						.finally(() => {
							loading.close();
						});
				}
				if (this.$refs.byBidwinner.isData) {
					// 表格下载
					const loading = this.$loading({
						lock: true,
						text: "导出中...",
						spinner: "el-icon-loading",
						background: "rgba(255, 255, 255, 0.3)",
						fullscreen: false,
					});
					this.$api.industry
						.exportHistoryPro(paramsObj)
						.then(res => {
							this.$utils.downloadFile(res, "历史项目数据-按中标人.xlsx");
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						})
						.finally(() => {
							loading.close();
						});
				}
			}
		},
		/*
		 * element  导出的元素
		 * filename 文件名
		 * ext      扩展文件名
		 */
		exportImg(element, filename, ext) {
			const loading = this.$loading({
				lock: true,
				text: "导出中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			html2canvas(element, {
				useCORS: true,
				height: element.scrollHeight,
				windowHeight: element.scrollHeight,
				width: element.width,
				windowWidth: element.width,
			}).then(canvas => {
				loading.close();
				if (navigator.msSaveBlob) {
					const blob = canvas.msToBlob(); // IE10+
					return navigator.msSaveBlob(blob, name);
				} else {
					const imageurl = canvas.toDataURL("image/png");
					const aLink = document.createElement("a"); // 创建a标签
					aLink.style.display = "none";
					aLink.href = imageurl;
					aLink.download = `${filename}.${ext}`; // 下载文件名
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); // 用完后移除元素
				}
			});
		},
		// 输入最低/最高金额后
		blurPrice(type) {
			let {winnerPriceStart, winnerPriceEnd} = this.conPO;
			if (type == "start") {
				if (+winnerPriceStart > +winnerPriceEnd && winnerPriceEnd != "") {
					this.$message.error("最低金额不能大于最高金额");
					this.conPO.winnerPriceStart = "";
				}
			} else {
				if (+winnerPriceEnd < +winnerPriceStart && winnerPriceStart != "") {
					this.$message.error("最高金额不能小于最低金额");
					this.conPO.winnerPriceEnd = "";
				}
			}
		},
		// 点击历史项目数据中的搜索
		handleQuery() {
			let userInfo = localStorage.getItem("LOGIN_USERS_KEY");
			console.log("userInfo", JSON.parse(userInfo));
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let params = {
					moduleCode: 1007,
					childModuleCode: 10071001,
					type: 2,
				};
				this.$api.industry
					.getMemberPermission(params)
					.then(res => {
						console.log("res", res);
						if (res.data != 0 && res.data > 0) {
							this.open(res.data);
						} else if (res.data < 0) {
							this.getParams();
						} else {
							this.text = "您的次数已用完，升级VIP会员";
							this.$refs.toPayOrMember.openDialog();
						}
					})
					.finally(() => {
						loading.close();
					});
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		open(tiems) {
			this.$confirm(`您还剩余${tiems}次查阅机会，是否消耗1次？`, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				this.conPO.defaultAccess = false;
				this.getParams();
			});
		},
		getParams() {
			this.$refs.rulesPrice.validate(valid => {
				if (valid) {
					let {conPO, mdsClientInfo, id} = this;
					conPO.clientId = id;
					let params = JSON.parse(JSON.stringify(conPO));
					this.params = params;
					this.showType = 1;
					delete params.pageSize;
					delete params.pageNum;
					if (conPO.navIndex == 1) {
						conPO.tenderee = mdsClientInfo.clientName;
						this.$refs.byTenderee.getParams(params, 1);
					} else {
						conPO.bidWinner = mdsClientInfo.clientName;
						this.$refs.byBidwinner.getParams(params, 1);
					}
				} else {
					return false;
				}
			});
		},
		// 资料报告库  右侧文件查看
		handleViewFile(row) {
			if (row.uploadWay == "60471001") {
				// 富文本
				this.$api.personalCenter
					.getFileDtl(row.folderFileId)
					.then(res => {
						this.dtlVisible = true;
						setTimeout(() => {
							document.getElementById("dtlPreview").innerHTML = res.data.detail;
						}, 100);
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				// 文件
				this.$api.industry
					.getClientInfoDownload({folderFileId: row.folderFileId})
					.then(res => {
						this.$utils.downloadFile(res, row.fileName + "." + row.fileExtension);
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {});
				// this.$api.personalCenter
				//   .getFileInfo({ fileId: row.fileId })
				//   .then((res) => {
				//     let url =
				//       process.env.VUE_APP_FILEURL + res.data.fileDir + "/" + res.data.fileKey;
				//     window.open(url, "_blank");
				//   })
				//   .catch((msg) => {
				//     if (msg?.msg) {
				//   this.$message.error(msg?.msg);
				// }
				//   });
			}
		},
	},
};
</script>

<style lang="less">
@import "./index.less";
</style>
